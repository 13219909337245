import React from 'react'

import Logo from '../../Assets/footer/Logo.png'
import SocialMedia from '../../Assets/footer/SocialMedia.png'

import './footer.css'

const Footer = () => {
  return (
    <div className='app__footer'>
      <div className='app__footer-contact'>
          <p className='p__opensans'>+94 77 458 8167</p>
          <p className='p__opensans address'> A. A. Peter Appuhami Road, </p>
          <p className='p__opensans'> Lunuwila, Wennappuwa</p>
      </div>

      <div className='app__footer-logo'>
        <img src={Logo} />
      </div>

      <div className='app__footer-socialmedia'>
        <a href='https://www.facebook.com/profile.php?id=61564182564074' target="_blank" rel="noopener noreferrer">
          <img src={SocialMedia} />
        </a>
      </div>
      
    </div>
  )
}

export default Footer
