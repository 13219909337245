import bridal1 from '../../Assets/workPage/bridal1.png'
import bridal2 from '../../Assets/workPage/bridal2.png'
import bridal3 from '../../Assets/workPage/bridal3.png'
import bridal4 from '../../Assets/workPage/bridal4.png'
import bridal5 from '../../Assets/workPage/bridal5.png'

import normal1 from '../../Assets/workPage/normal1.png'
import normal2 from '../../Assets/workPage/normal2.png'
import normal3 from '../../Assets/workPage/normal3.png'
import normal4 from '../../Assets/workPage/normal4.png'
import normal5 from '../../Assets/workPage/normal5.png'
import normal6 from '../../Assets/workPage/normal6.png'
import normal7 from '../../Assets/workPage/normal7.png'
import normal8 from '../../Assets/workPage/normal8.png'
import normal9 from '../../Assets/workPage/normal9.png'
import normal10 from '../../Assets/workPage/normal10.png'

import hair1 from '../../Assets/workPage/hair1.png'
import hair2 from '../../Assets/workPage/hair2.png'
import hair3 from '../../Assets/workPage/hair3.png'
import hair4 from '../../Assets/workPage/hair4.png'
import hair5 from '../../Assets/workPage/hair5.png'

const workArray = [
    {title:"Bridal Makeup", img: [bridal1,bridal2,bridal3,bridal4,bridal5]},
    {title:"Special Occasion Makeup", img: [normal1, normal2, normal3, normal4,normal5, normal6, normal7, normal8, normal9, normal10]},
    {title:"Hair Cuts & Colouring", img: [hair1, hair2, hair3, hair4, hair5]}
]

export {
    workArray
}