const descMakeup = [
    {name:"Bridal Makeup", desc:'Look stunning on your special day with customized makeup that complements your features and lasts all day.'},
    {name:"Special Occasion Makeup", desc:'Perfect for parties, or any special event where you want to look and feel glamorous.'},
    {name:"Evening/Party Makeup", desc:'Bold, dramatic makeup for a glamorous night out, complete with smoky eyes and striking lips.'},
    {name:"Makeup for Photoshoots", desc:' Flawless, long-lasting makeup designed for photography, ensuring you look perfect in every shot.'},
]

const descSkin = [
    {name:"Facials", desc:'Custom facials to suit all skin types, targeting hydration, anti-aging, acne, and more.'},
    {name:"Peels & Exfoliation", desc:'Gentle peels to improve texture, tone, and clarity.'},
    {name:"Advanced Skin Treatments", desc:'Microdermabrasion and LED therapy to brighten and rejuvenate your skin.'},
]

const descHair = [
    {name:"Hair Cutting", desc:'Customized cuts for women.'},
    {name:"Hair Colouring", desc:'Full color, balayage, ombre, highlights, and lowlights.'},
    {name:"Hair Treatments", desc:'Deep conditioning, Keratin treatments,  Oil Treatment, Relaxing, Hair Straightening'},
    {name:"Blowouts & Styling", desc:'Perfect for special occasions or everyday glam. We also offer updos and braiding.'},
]

const descNail = [
    {name:"Manicures", desc:'Classic, gel, and acrylic manicures, including custom nail art and French tips.'},
    {name:"Pedicures", desc:'Soothing pedicures for soft, beautiful feet with options for gel or polish finishes.'},
    {name:"Nail Art", desc:'Choose from a variety of creative designs to express your style.'},
]

const descWax = [
    {name:"Face Waxing", desc:'Eyebrows, upper lip, chin, and full face waxing for clean, defined results.'},
    {name:"Body Waxing", desc:'Arms, legs, back, and chest waxing using high-quality wax for minimal discomfort.'},
    {name:"Bikini & Brazilian Waxing", desc:'Gentle and precise waxing for a smooth bikini area.'},
]

export {
    descMakeup,
    descSkin,
    descHair,
    descNail,
    descWax
}