import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../Assets/headerLogo.png'
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={logo} alt="app__logo" />
      </div>
      <div className='app__navbar-links'>
        <NavLink to="/" className='p__opensans' activeClassName="active-link">
          Home
        </NavLink>
        <NavLink to="/services" className='p__opensans' activeClassName="active-link">
          Our Services
        </NavLink>
        <NavLink to="/works" className='p__opensans' activeClassName="active-link">
          Our Works
        </NavLink>
        <NavLink to="/contact" className='p__opensans' activeClassName="active-link">
          Contact Us
        </NavLink>
      </div>
      <div className="app__navbar-smallscreen">
        <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <RiCloseLine color="#fff" size={27} className='overlay__close' onClick={() => setToggleMenu(false)} />
            <div className='app__navbar-smallscreen_links'>
              <NavLink to="/" activeClassName="active-link" onClick={() => setToggleMenu(false)}>
                Home
              </NavLink>
              <NavLink to="/services"  activeClassName="active-link" onClick={() => setToggleMenu(false)}>
                Our Services
              </NavLink>
              <NavLink to="/works"  activeClassName="active-link" onClick={() => setToggleMenu(false)}>
                Our Works
              </NavLink>
              <NavLink to="/contact"  activeClassName="active-link" onClick={() => setToggleMenu(false)}>
                Contact Us
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
