import React from 'react'

import Navbar from '../../Comps/navbar/Navbar'
import Brush from '../../Assets/makeupBrush.png'
import Image from '../../Assets/homePage/headerimage.png'

import './header.css'

const Header = () => {
  return (
    <div>
      <div className='app__header app__wrapper'>
        <div className='app__header-info app__wrapper_info'>
          <div className='app__header-info__title'>
            <h1>Where beauty meets </h1>
            <h1>relaxation.</h1>
          </div>
          <img src={Brush}/>
          <p className='p__opensans'>We believe in making every client feel beautiful, confident, and refreshed. 
            Whether you're looking for a stylish haircut, a fresh color, or a relaxing treatment, we are here to 
            deliver the look and experience you deserve.
          </p>
        </div>
        <div className='app__header-img'>
          <img src={Image} alt='HomePage Image'/>
        </div>
      </div>
    </div>
  )
}

export default Header
