import React from 'react'
import './workFeature.css'

const WorkFeature = ({title,img}) => {
  if(img.length > 5){
    const firstFiveImages = img.slice(0, 5);
    const nextFiveImages = img.slice(5, 10);
    return (
      <div className='app__workFeature'>
        <h1 className='p__cormorant'>{title}</h1>
        <div className='app__workFeature__img flex__center'>
          <div className='app__workFeature__img-row1'>
            {firstFiveImages.map ((img) => (
              <img src={img} alt='images' />
            ))}
          </div>
          <div className='app__workFeature__img-row2'>
            {nextFiveImages.map ((img) => (
              <img src={img} alt='images' />
            ))}
          </div>
        </div>
      </div>
    )
  }

  
  else{
    return (
      <div className='app__workFeature'>
        <h1 className='p__cormorant'>{title}</h1>
        <div className='app__workFeature__img'>
          <div className='app__workFeature__img-row1'>
            {img.map ((img) => (
              <img src={img} alt='images' />
            ))}
          </div>
        </div>
      </div>
    )
  }
 
}

export default WorkFeature
