import React from 'react'
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

import Navbar from './Comps/navbar/Navbar';
import Footer from './Containers/footer/Footer'
import { HomePage, ContactPage, ServicePage, WorkPage } from './Pages'
import './App.css'

const App = () => {
  return (
    <BrowserRouter>
    <div className='App'>
      <Navbar/>
				<div>
					<Routes>
						<Route path="/" element={<HomePage />} />
            <Route path="/services" element={<ServicePage />} />
						<Route path="/works" element={<WorkPage />} />
						<Route path="/contact" element={<ContactPage />} />						
					</Routes>
				</div>      
      <Footer />
    </div>
    </BrowserRouter>
  )
}

export default App
