import React from 'react'

import Miurangana from '../../Assets/homePage/miurangana.png'
import Quotes from '../../Assets/homePage/quotes.png'

import './founder.css'

const Founder = () => {
  return (
    <div className='app__founder app__bg flex__center section__padding'>
        <div className='app__founder-img'>
            <img src={Miurangana} alt='Founder'/>
        </div>
        <div className='app__founder-info headtext__cormorant'>
            <h1>Miurangana Fernando</h1>
            <p className='p__opensans'>Beautician & Founder</p>
            <div className='app__founder-info__paragraph'>
                <img src={Quotes} alt='quotes' />
                <p className='p__opensans'>With years of experience in the beauty industry, Miurangana Fernando brings a wealth of expertise 
                    and creativity to Salon Miurangana. She is a licensed cosmetologist, with certifications in makeup, 
                    hair styling, color treatments, skincare and nails.
                </p>
            </div>
            
        </div>
    </div>
  )
}

export default Founder
