import React from 'react'

import Image from '../../Assets/homePage/logoWatermark.png'
import Brush from '../../Assets/makeupBrush.png'
import './ourStory.css'

const OurStory = () => {
  return (
    <div className='app__aboutus app__bg flex__center section__padding'>
        <div className="app__aboutus-overlay flex__center">
            <img src={Image} alt="G_overlay" />
        </div>

        <div className="app__aboutus-content flex__center">
            <div className="app__aboutus-content_about">
                <h1 className="headtext__cormorant">Our Story</h1>
                <img src={Brush} alt='makeup brush'/>          
                <p className="p__opensans">Salon Miurangana is the newest beauty salon in Wennappuwa, created with a passion for style and a commitment to exceptional customer service. We believe that everyone deserves to feel beautiful, and our mission is to offer a range of high-quality services that cater to your unique needs.</p>
            </div>
            <div className="app__aboutus-content_history">
                <h1 className="headtext__cormorant">Our Mission</h1>
                <img src={Brush} alt='makeup brush'/>                 
                <p className="p__opensans">At Salon Miurangana, our mission is to offer personalized beauty services that enhance confidence and well-being. We strive to provide each client with a relaxing, tailored experience that leaves them looking and feeling their best. Quality, care, and creativity are at the heart of everything we do.</p>
            </div>
        </div>      
    </div>
  )
}

export default OurStory
