import React from 'react'

import ServiceFeature from '../../Comps/serviceFeature/ServiceFeature'
import { img1, img2, img3, img4, img5 } from './imgImports'
import { descMakeup, descSkin, descHair, descNail, descWax} from './descArray'

import './servicePage.css'

const ServicePage = () => {
  const serviceArray = [
    { title: "Makeup Services", img: img1, desc: descMakeup },
    { title: "Skin Care", img: img2, desc: descSkin },
    { title: "Hair Care", img: img3, desc: descHair },
    { title: "Nail Care", img: img4, desc: descNail },
    { title: "Waxing", img: img5, desc: descWax },
  ]

  return (
    <div className='app__services app__background-color'>
      <h1 className='headtext__cormorant'>Our Services</h1>
      <div className='app__services-container'>
        {serviceArray.map((service, index) => (
          <ServiceFeature
            key={index}
            title={service.title}
            img={service.img}
            desc={service.desc}
          />
        ))}
      </div>
    </div>
  )
}

export default ServicePage

