import React from 'react'

import Brush from '../../Assets/makeupBrush.png'
import Map from '../../Assets/contactPage/map.png'

import './contactPage.css'

const ContactPage = () => {
  return (
    <div className='app__contact app__background-color'>
      <div className='app__contact-title'>
        <h1 className='headtext__cormorant'>Contact Us</h1>
        <img src={Brush} alt='makeup brush' />
      </div>
      <div className='app__contact-number'>
        <h1 className='p__cormorant'>To Make An Appointment</h1>
        <div className='app__contact-number-heading'>
          <p className='p__opensans'>
            Call or Whatsapp
          </p>
        </div>       
        <p className='p__opensans'>
          +94 77 458 8167 / +94 31 224 5485
        </p>
        <h1 className='p__cormorant'>Location</h1>
      </div>
      
      <div className='app__contact-map'>
        <a href='https://maps.app.goo.gl/uVWmV6KVRpbh1R3r9' target="_blank" rel="noopener noreferrer">
          <img src={Map} alt='Map' />
          <span class="hover-message">Click here to view on Google Maps</span>
        </a>
      </div>
    </div>
  )
}

export default ContactPage
