import React from 'react'

import Header from '../../Containers/header/Header'
import OurStory from '../../Containers/ourStory/OurStory'
import Founder from '../../Containers/Founder/Founder'

import './homePage.css'

const HomePage = () => {
  return (
    <div className='app__background-color'>
      <Header />
      <OurStory />
      <Founder />
    </div>
  )
}

export default HomePage
