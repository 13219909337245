import React from 'react'

import WorkFeature from '../../Comps/workFeature/WorkFeature'
import { workArray } from './imports'

import './workPage.css'

const WorkPage = () => {
  return (
    <div className='app__works app__background-color'>
      <h1 className='headtext__cormorant'>Our Works</h1>
      <div className='app__works-container'>
        {workArray.map((work, index) => (
          <WorkFeature
            key={index}
            title={work.title}
            img={work.img}
          />
        ))}
      </div>
    </div>
  )
}

export default WorkPage
