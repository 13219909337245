import React from 'react'

import Brush from '../../Assets/makeupBrush.png'
import Dot from '../../Assets/servicePage/dot.png'

import './serviceFeature.css'

const ServiceFeature = ({title, img, desc}) => {
  return (
    <div className='app__serviceFeature app__bg section__padding'>
      <div className='app__serviceFeature-feature'>       
        <h1 className='p__cormorant'>{title}</h1>
        <img src={Brush} />
        
        {desc.map((subdesc) => (
          <div className='app__serviceFeature-feature-desc'>
            <div className='app__serviceFeature-feature-desc-title'>
              <img src={Dot} />
              <h1 className='p__opensans'>{subdesc.name}</h1>
            </div>
            <p className='p__opensans'>{subdesc.desc}</p>            
          </div>
        ))}

      </div>
      
      <div className='app__serviceFeature-img'>
        <img src={img} />
      </div>        
    </div>
  )
}

export default ServiceFeature
